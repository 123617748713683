import React from "react";
// import Layout from "../../components/Layout";
import { OverlayTrigger, Popover } from "react-bootstrap";

const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Email Signature Copied!</Popover.Title>
    <Popover.Content>
      Your email signature has been copied to your clipboard!
    </Popover.Content>
  </Popover>
);

const EmailWithVideo = () => (
  <table style={{ border: "none", borderCollapse: "collapse" }}>
    <tbody>
      <tr style={{ height: "0pt" }}>
        {/* Left Side */}
        <td
          style={{
            borderWidth: "1pt 4pt 1pt 1pt",
            borderStyle: "solid",
            borderColor: "#ffffff #007EB5 #ffffff #ffffff",
            verticalAlign: "top",
            padding: "5pt 12pt 5pt 5pt",
            width: "150px"
          }}
        >
          <div style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt", textAlign: "center" }}>
            <img
              style={{ fontSize: "12.8px", borderRadius: "50%" }}
              tabIndex={0}
              src="https://3playmedia-wpengine.netdna-ssl.com/wp-content/uploads/jerickson-200x235.jpg"
              alt="{}"
              width={80}
              height={94}
            />
          </div>
          <div
            style={{
              boxShadow: "4px 4px 0px 0px rgba(196,196,196,1)",
              backgroundColor: "#007eb5",
              padding: "0px 0px 6px 0px",
              borderRadius: "2px",
              marginTop: "7px",
              color: "white",
              textAlign: "center"
            }}
          >
            <a href="www.google.com" target="_blank" rel="noopener">
              <img
                src="https://www.3playmedia.com/wp-content/uploads/play-button-1.png"
                alt="{}"
                width={20}
                height={20}
              />
              <span
                style={{
                  paddingLeft: "7px",
                  fontSize: "12pt",
                  verticalAlign: "bottom",
                  display: "inline-block",
                  color: "white",
                  fontWeight: "bold",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                About Me
              </span>
            </a>
          </div>
        </td>
        {/* White Space */}
        <td
          style={{
            borderWidth: "1pt 1pt 1pt 1.5pt",
            borderStyle: "solid",
            borderColor: "#ffffff #ffffff #ffffff #0095d6",
            verticalAlign: "top",
            padding: "2pt"
          }}
        >
          <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>&nbsp;</span>
        </td>
        {/* Right Side */}
        <td style={{ verticalAlign: "top", padding: "2pt", border: "1pt solid #ffffff" }}>
          {/* Name and Position */}
          <p dir="ltr" style={{ minHeight: "28px", lineHeight: "1.368", marginTop: "6pt", marginBottom: "0pt" }}>
            <span
              style={{
                fontSize: "16pt",
                color: "#007eb5",
                backgroundColor: "transparent",
                fontWeight: "bold",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              {"{"}Your Name Here{"}"}
            </span>
            <span
              style={{
                fontSize: "16pt",
                color: "#4f4f4f",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              &nbsp;●
              {"{"}Your Position Here{"}"}
            </span>
          </p>
          {/* Email and Website */}
          <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
            <span
              style={{
                fontSize: "12pt",
                color: "#4f4f4f",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              {"{"}Your Email Address Here{"}"}
            </span>
            <span
              style={{
                fontSize: "12pt",
                color: "#4f4f4f",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              &nbsp;●
              <a href="www.3playmedia.com" target="_blank" rel="noopener">
                www.3playmedia.com
              </a>
            </span>
          </p>
          {/* Phone Numbers */}
          <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
            <span
              style={{
                fontSize: "12pt",
                color: "#007eb5",
                fontWeight: "bold",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              work:
              <span
                style={{
                  fontSize: "12pt",
                  fontWeight: 500,
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                {"{"}Work # Here{"}"}
              </span>{" "}
            </span>{" "}
            <span
              style={{
                fontSize: "12pt",
                color: "#007eb5",
                fontWeight: "bold",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              cell:
              <span
                style={{
                  fontSize: "12pt",
                  fontWeight: 500,
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                {"{"}Cell # Here{"}"}
              </span>{" "}
            </span>
          </p>
          {/* Social Media Icons and Logo */}
          <div>
            <p dir="ltr" style={{ lineHeight: "1.368", marginTop: "17pt", marginBottom: "0pt" }}>
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/facebook-2.png"
                  alt="Facebook"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/twitter.png"
                  alt="Twitter"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/linkedin.png"
                  alt="LinkedIn"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/instagram-1.png"
                  alt="Instagram"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/youtube-1.png"
                  alt="Schedule a Meeting"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  paddingLeft: "10px",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/logo-3.52.05-PM.png"
                  alt="3Play Media Logo"
                  width={172}
                  height={34}
                />
              </span>
            </p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

const EmailWithoutVideo = () => (
  <table style={{ border: "none", borderCollapse: "collapse" }}>
    <tbody>
      <tr style={{ height: "0pt" }}>
        {/* Left Side */}
        <td
          style={{
            borderWidth: "1pt 4pt 1pt 1pt",
            borderStyle: "solid",
            borderColor: "#ffffff #007EB5 #ffffff #ffffff",
            verticalAlign: "top",
            padding: "5pt 12pt 5pt 5pt",
            width: "150px"
          }}
        >
          <div style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt", textAlign: "center" }}>
            <img
              style={{ fontSize: "12.8px", borderRadius: "50%" }}
              tabIndex={0}
              src="https://3playmedia-wpengine.netdna-ssl.com/wp-content/uploads/jerickson-200x235.jpg"
              alt="asd"
              width={80}
              height={94}
            />
          </div>
          <span
            style={{
              fontSize: "12pt",
              color: "#4f4f4f",
              backgroundColor: "transparent",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
              fontFamily: "arial, helvetica, sans-serif"
            }}
          >
            <img
              src="https://www.3playmedia.com/wp-content/uploads/logo-3.52.05-PM.png"
              alt="3Play Media Logo"
              width={175}
              height={34}
            />
          </span>
        </td>
        {/* White Space */}
        <td
          style={{
            borderWidth: "1pt 1pt 1pt 1.5pt",
            borderStyle: "solid",
            borderColor: "#ffffff #ffffff #ffffff #0095d6",
            verticalAlign: "top",
            padding: "2pt"
          }}
        >
          <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>&nbsp;</span>
        </td>
        {/* Right Side */}
        <td style={{ verticalAlign: "top", padding: "2pt", border: "1pt solid #ffffff" }}>
          {/* Name and Position */}
          <p dir="ltr" style={{ minHeight: "28px", lineHeight: "1.368", marginTop: "6pt", marginBottom: "0pt" }}>
            <span
              style={{
                fontSize: "16pt",
                color: "#007eb5",
                backgroundColor: "transparent",
                fontWeight: "bold",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              {"{"}Your Name Here{"}"}
            </span>
            <span
              style={{
                fontSize: "16pt",
                color: "#4f4f4f",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              &nbsp;●
              {"{"}Your Position Here{"}"}
            </span>
          </p>
          {/* Email and Website */}
          <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
            <span
              style={{
                fontSize: "12pt",
                color: "#4f4f4f",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              {"{"}Your Email Address Here{"}"}
            </span>
            <span
              style={{
                fontSize: "12pt",
                color: "#4f4f4f",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              &nbsp;●
              <a href="www.3playmedia.com" target="_blank" rel="noopener">
                www.3playmedia.com
              </a>
            </span>
          </p>
          {/* Phone Numbers */}
          <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
            <span
              style={{
                fontSize: "12pt",
                color: "#007eb5",
                fontWeight: "bold",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              work:
              <span
                style={{
                  fontSize: "12pt",
                  fontWeight: 500,
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                {"{"}Work # Here{"}"}
              </span>{" "}
            </span>{" "}
            <span
              style={{
                fontSize: "12pt",
                color: "#007eb5",
                fontWeight: "bold",
                backgroundColor: "transparent",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                fontFamily: "arial, helvetica, sans-serif"
              }}
            >
              cell:
              <span
                style={{
                  fontSize: "12pt",
                  fontWeight: 500,
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                {"{"}Cell # Here{"}"}
              </span>{" "}
            </span>
          </p>
          {/* Social Media Icons and Logo */}
          <div>
            <p dir="ltr" style={{ lineHeight: "1.368", marginTop: "15pt", marginBottom: "0pt" }}>
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/facebook-2.png"
                  alt="Facebook"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/twitter.png"
                  alt="Twitter"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/linkedin.png"
                  alt="LinkedIn"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://lh3.google.com/u/1/d/1d3ASMxk09nQvnMXiR_v2M9VWfgcUaoE7=w2620-h1891-iv1"
                  alt="Instagram"
                  width={30}
                  height={30}
                />
              </span>{" "}
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/youtube-1.png"
                  alt="Schedule a Meeting"
                  width={30}
                  height={30}
                />
              </span>{" "}
            </p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

export default class EmailSignatureConfirmation extends React.Component {
  constructor() {
    super()
    console.log(this);
  }

  copySignature() {
    var emailSignature = document.getElementById('email-sig').innerHTML;
    console.log(emailSignature);
  }

  render() {
    const { params } = this.props;
    console.log('params are: ', params)
    return (
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Here's your email signature!</h1>
              <p>This is your custom email signature:</p>
              <div id="email-sig">
                <EmailWithVideo />
                <EmailWithoutVideo />
              </div>
              <br />
              <h4>Steps to Copy Your Signature into Gmail</h4>
              <ol>
                <li>Click the Following Button</li>
                <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                  <button id="email-signature" onClick={this.copySignature} >Copy to Clipboard</button>
                </OverlayTrigger>
                
                {/* onClick={this.copyToClip(document.getElementById('email-sig'))} */}
                <li>Open Gmail</li>
              </ol>
            </div>
          </div>
        </section>
    );
  }
}
